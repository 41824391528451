
import { defineComponent, ref } from "vue";
import { useRouter } from "vue-router";

import { IContact } from "@/views/Contact/Contracts";
import BaseInput from "@/components/BaseInput.vue";
import ContactService from "@/Services/ContactService";
import { AxiosResponse } from "axios";

export default defineComponent({
  name: "ContactForm",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  components: {
    BaseInput,
  },
  setup(props) {
    const router = useRouter();
    const error = ref("");
    const contact = ref<IContact>({
      id: "",
      FirstName: "",
      LastName: "",
    });

    ContactService.getContact(props.id)
      .then((response) => {
        console.log(response);
        contact.value = response.data;
      })
      .catch((ex) => {
        console.error(ex);
      });

    const onSubmit = () => {
      //TODO: validate form input

      if (contact.value.id) {
        ContactService.putContact(contact.value)
          .then((response) => onSaved(response))
          .catch((ex) => onError(ex));
      } else {
        ContactService.postContact(contact.value)
          .then((response) => onSaved(response))
          .catch((ex) => onError(ex));
      }
    };
    const onBack = () => {
      router.back();
    };
    const onSaved = (response: AxiosResponse<IContact>): void => {
      console.log(response);
      contact.value = response.data;
      onBack();
    };
    const onError = (ex: any): void => {
      if (ex.response) {
        // The request was made and the server responded with a status code that falls out of the range of 2xx
        console.log(ex.response.data);
        console.log(ex.response.status);

        //set form error
        let message: string =
          ex.response.status + " : " + ex.response.statusText;
        error.value = message;
        console.log(message);
      } else {
        //some unknown error
        console.error(ex);

        //snackbar error
      }
    };
    const onDelete = () => {
      ContactService.deleteContact(contact.value.id)
        .then((response) => onSaved(response))
        .catch((ex) => onError(ex));
    };
    return {
      contact,
      error,
      onSubmit,
      onDelete,
      onBack,
    };
  },
});
