<template>
  <div class="events">
    <h1>Events for Good</h1>
    <EventCard v-for="event in events" :key="event.id" :event="event" />
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import EventCard from "../components/EventCard.vue";
import EventService from "@/Services/EventService";
import { TEvent } from "@/Types";

export default defineComponent({
  name: "EventsList",
  components: {
    EventCard,
  },
  setup() {
    //api call goes here.
    const events = ref([] as TEvent[]);
    EventService.getEvents()
      .then((response) => {
        events.value = response.data;
      })
      .catch((ex) => console.error(ex));

    return {
      events,
    };
  },
});
</script>

<style scoped>
.events {
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* to have the style applied globally, move to app.vue style */
h4 {
  font-size: 20px;
}
</style>
