<template>
  <label :for="uuid" v-if="label">{{ label }} </label>
  <input
    :placeholder="label"
    :value="modelValue"
    @input="$emit('update:modelValue', $event.target.value)"
    v-bind="$attrs"
    class="field"
    :id="uuid"
    :aria-describedby="error ? `${uuid}-error` : null"
    :aria-invalid="error ? true : null"
  />
  <p
    :id="`${uuid}-error`"
    v-if="error"
    class="errorMessage"
    aria-live="assertive"
  >
    {{ error }}
  </p>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { UniqueID } from "@/Features/uuid";

export default defineComponent({
  name: "BaseInput",
  props: {
    label: {
      type: String,
    },
    modelValue: {
      type: [String, Number],
    },
    error: {
      type: String,
      default: "",
    },
  },
  setup() {
    const uuid: number = UniqueID();
    return { uuid };
  },
});
</script>
