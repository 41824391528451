
import { defineComponent, ref } from "vue";
import EventCard from "../components/EventCard.vue";
import EventService from "@/Services/EventService";
import { TEvent } from "@/Types";

export default defineComponent({
  name: "EventsList",
  components: {
    EventCard,
  },
  setup() {
    //api call goes here.
    const events = ref([] as TEvent[]);
    EventService.getEvents()
      .then((response) => {
        events.value = response.data;
      })
      .catch((ex) => console.error(ex));

    return {
      events,
    };
  },
});
