<template>
  <h1>404 Not Found</h1>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
  name: "404NotFound",
});
</script>
