//TODO: use codeGen to generate from C#
import apiClient from "./apiClient";
import { AxiosResponse } from "axios";
import { IContact } from "@/views/Contact/Contracts";

export default {
  getContacts(): Promise<AxiosResponse<IContact[]>> {
    return apiClient.get("/contacts");
  },
  getContact(id: string): Promise<AxiosResponse<IContact>> {
    return apiClient.get("/contacts/" + id);
  },
  postContact(newContact: IContact): Promise<AxiosResponse<IContact>> {
    return apiClient.post("/contacts", newContact);
  },
  putContact(existingContact: IContact): Promise<AxiosResponse<IContact>> {
    return apiClient.put("/contacts/" + existingContact.id, existingContact);
  },
  deleteContact(id: string): Promise<AxiosResponse<IContact>> {
    return apiClient.delete("/contacts/" + id);
  },
};
