
import { defineComponent } from "vue";
import BaseRadio from "@/components/BaseRadio.vue";
export default defineComponent({
  name: "BaseRadioGroup",
  components: { BaseRadio },
  props: {
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: [Number, String],
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
});
