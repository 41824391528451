
import { defineComponent } from "vue";
import { UniqueID } from "@/Features/uuid";
export default defineComponent({
  name: "BaseSelect",
  props: {
    label: {
      type: String,
    },
    modelValue: {
      type: [String, Number],
      default: "",
    },
    options: {
      type: Array,
      required: true,
    },
  },

  setup() {
    const uuid: number = UniqueID();
    return { uuid };
  },
});
