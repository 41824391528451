
import { defineComponent, ref } from "vue";
import { TEvent } from "@/Types";
import EventService from "@/Services/EventService";

export default defineComponent({
  name: "EventDetails",
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const event = ref({} as TEvent);

    EventService.getEvent(props.id)
      .then((response) => {
        event.value = response.data;
      })
      .catch((ex) => console.error(ex));

    return {
      event,
    };
  },
});
