//Routes for contact samples
import { RouteRecordRaw } from "vue-router";

import ContactForm from "@/views/Contact/ContactForm.vue";
import ContactList from "@/views/Contact/ContactList.vue";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/contacts",
    name: "contacts",
    component: ContactList,
  },
  {
    path: "/contact/:id?", //id is optional
    name: "contact",
    component: ContactForm,
    props: true,
  },
];
