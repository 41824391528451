
import { defineComponent } from "vue";
import { UniqueID } from "@/Features/uuid";
export default defineComponent({
  name: "BaseRadio",
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    modelValue: {
      type: [String, Number],
    },
  },
  setup() {
    const uuid: number = UniqueID();
    return { uuid };
  },
});
