
import { defineComponent } from "vue";

export default defineComponent({
  name: "Products",
  setup() {
    const product = "Socks";
    const image = "../assets/images/socks_green.jpg";
    const inventory = 12;
    const onSale = false;
    const details: string[] = ["50% Cotton", "30% wool", "20% polyester"];
    const variants = [
      { id: 123, color: "green" },
      { id: 456, color: "blue" },
    ];
    const sizes: string[] = ["S", "M", "L", "XL"];
    return {
      product,
      image,
      inventory,
      onSale,
      details,
      variants,
      sizes,
    };
  },
});
