
import { defineComponent, ref } from "vue";
import ContactService from "@/Services/ContactService";
import { IContact } from "@/views/Contact/Contracts";
export default defineComponent({
  name: "ContactList",
  setup() {
    const contacts = ref([] as Array<IContact>);

    ContactService.getContacts()
      .then((response) => {
        console.log(response);
        contacts.value = response.data;
      })
      .catch((ex) => console.error(ex));

    return {
      contacts,
    };
  },
});
