
//Component defined using the options api using TypeScript
import { defineComponent, PropType } from "vue";
import { person } from "../typescript.samples/propTypes";

export default defineComponent({
  name: "TSComponentOptionsApi",
  props: {
    person: {
      type: Object as PropType<person>, //Typed Prop
      required: false,
      default: { firstName: "simon", lastName: "hooper", id: 25 } as person,
    },
  },
  computed: {
    fullname(): string {
      return this.$props.person.firstName + " " + this.$props.person.lastName;
    },
  },
});
