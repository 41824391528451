
import { defineComponent } from "vue";
import { UniqueID } from "@/Features/uuid";

export default defineComponent({
  name: "BaseCheckbox",
  props: {
    label: {
      type: String,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const uuid: number = UniqueID();
    return { uuid };
  },
});
