
import { defineComponent, reactive, ref, toRefs } from "vue";
import BaseInput from "@/components/BaseInput.vue";
import BaseSelect from "@/components/BaseSelect.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import BaseRadioGroup from "@/components/BaseRadioGroup.vue";

export default defineComponent({
  name: "BasicForm",
  components: {
    BaseRadioGroup,
    BaseCheckbox,
    BaseSelect,
    BaseInput,
  },
  setup() {
    const data = reactive({
      categories: [
        "sustainability",
        "nature",
        "animal welfare",
        "housing",
        "education",
        "food",
        "community",
      ],
      petOptions: [
        { label: "Yes", value: 1 },
        { label: "No", value: 0 },
      ],
    });

    const event = ref({
      category: "",
      title: "",
      description: "",
      location: "",
      pets: 1,
      extras: {
        catering: false,
        music: false,
      },
    });

    const sendForm = () => {
      console.log(event);
    };

    return {
      ...toRefs(data),
      event,
      sendForm,
    };
  },
});
