<template>
  <component
    v-for="option in options"
    :key="option"
    :is="vertical ? 'div' : 'span'"
    :class="{
      horizontal: !vertical,
    }"
  >
    <BaseRadio
      :name="name"
      :label="option.label"
      :value="option.value"
      :modelValue="modelValue"
      @update:modelValue="$emit('update:modelValue', $event)"
    />
  </component>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import BaseRadio from "@/components/BaseRadio.vue";
export default defineComponent({
  name: "BaseRadioGroup",
  components: { BaseRadio },
  props: {
    name: {
      type: String,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
    modelValue: {
      type: [Number, String],
    },
    vertical: {
      type: Boolean,
      default: false,
    },
  },
});
</script>

<style scoped>
.horizontal {
  margin-right: 20px;
}
</style>
