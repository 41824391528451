//VueMastery Course - Vue Router - https://www.vuemastery.com/courses/touring-vue-router/vue-router-introduction
//DOCS: Router history mode https://router.vuejs.org/guide/essentials/history-mode.html#example-server-configurations
// Access Router in Setup: https://next.router.vuejs.org/guide/advanced/composition-api.html

import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router";
import * as TS from "../typescript.samples/routes";
import { routes as contactRoutes } from "@/views/Contact/routes";

import Home from "../views/Home.vue";
import EventList from "../views/EventList.vue";
import EventDetails from "@/views/EventDetails.vue";
import About from "../views/About.vue";
import Product from "@/views/Product.vue";
import basicForm from "@/views/BasicForm.vue";
import NotFound404 from "@/views/404.vue";

let routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/events",
    name: "events",
    component: EventList,
  },
  {
    path: "/event/:id",
    name: "EventDetails",
    component: EventDetails,
    props: true,
  },
  {
    path: "/product",
    name: "product",
    component: Product,
  },
  {
    path: "/form",
    name: "basicForm",
    component: basicForm,
  },
  {
    path: "/about",
    name: "about",
    component: About,
  },

  // use for performance - when the app is large  -code splitting
  // {
  //   path: "/about",
  //   name: "About",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/About.vue"),
  // },

  {
    //404 catch all
    path: "/:pathMatch(.*)",
    redirect: { name: "NotFound" },
  },
  {
    path: "/404",
    name: "NotFound",
    component: NotFound404,
  },
];

routes = routes.concat(TS.routes).concat(contactRoutes);

console.log("loading routes");
routes.forEach((route) => {
  console.log(route);
});

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
