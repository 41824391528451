<template>
  <h1>Contacts</h1>
  <router-link :to="{ name: 'contact' }"> New Contact </router-link>
  <div v-for="contact in contacts" :key="contact">
    <router-link :to="{ name: 'contact', params: { id: contact.id } }">
      <p>{{ contact.FirstName }} {{ contact.LastName }}</p>
    </router-link>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import ContactService from "@/Services/ContactService";
import { IContact } from "@/views/Contact/Contracts";
export default defineComponent({
  name: "ContactList",
  setup() {
    const contacts = ref([] as Array<IContact>);

    ContactService.getContacts()
      .then((response) => {
        console.log(response);
        contacts.value = response.data;
      })
      .catch((ex) => console.error(ex));

    return {
      contacts,
    };
  },
});
</script>
