<template>
  <input
    type="checkbox"
    :checked="modelValue"
    @change="$emit('update:modelValue', $event.target.checked)"
    class="field"
    :id="uuid"
  />
  <label :for="uuid" v-if="label">{{ label }}</label>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { UniqueID } from "@/Features/uuid";

export default defineComponent({
  name: "BaseCheckbox",
  props: {
    label: {
      type: String,
    },
    modelValue: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const uuid: number = UniqueID();
    return { uuid };
  },
});
</script>

<style scoped></style>
