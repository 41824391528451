
//Component defined using the options api using TypeScript
import { defineComponent, PropType, reactive, computed, toRefs } from "vue";
import { person } from "../typescript.samples/propTypes";

export default defineComponent({
  name: "TSComponentCompositionApi",
  props: {
    person: {
      type: Object as PropType<person>, //Typed Prop
      required: false,
      default: { id: 15, firstName: "simon", lastName: "hooper" } as person,
    },
  },
  setup(props) {
    const data = reactive({});

    const fullname = computed(() => {
      return props.person.firstName + " " + props.person.lastName;
    });

    return {
      ...toRefs(data), //converts each property to a reactive 'ref'
      data,
      fullname,
    };
  },
});
