//Routes for typescript samples
import { RouteRecordRaw } from "vue-router";
import TSComponentOptionsApi from "../typescript.samples/TSComponentOptionsApi.vue";
import TSComponentCompositionApi from "../typescript.samples/TSComponentCompositionApi.vue";

export const routes: Array<RouteRecordRaw> = [
  {
    path: "/ts/composition",
    name: "composition-api",
    component: TSComponentCompositionApi,
  },
  {
    path: "/ts/options",
    name: "options-api",
    component: TSComponentOptionsApi,
  },
];
