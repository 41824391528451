import { AxiosResponse } from "axios";
import { TEvent } from "@/Types";
import apiClient from "./apiClient";

export default {
  getEvents(): Promise<AxiosResponse<TEvent[]>> {
    return apiClient.get("/events");
  },
  getEvent(id: string): Promise<AxiosResponse<TEvent>> {
    return apiClient.get("/events/" + id);
  },
};
