<template>
  <input
    type="radio"
    :value="0"
    :name="name"
    :checked="modelValue === value"
    @change="$emit('update:modelValue', value)"
    v-bind="$attrs"
    :id="uuid"
  />
  <label :for="uuid" v-if="label">{{ label }}</label>
</template>

<script lang="ts">
import { defineComponent } from "vue";
import { UniqueID } from "@/Features/uuid";
export default defineComponent({
  name: "BaseRadio",
  props: {
    name: {
      type: String,
      required: true,
    },
    label: {
      type: String,
    },
    value: {
      type: [String, Number],
      required: true,
    },
    modelValue: {
      type: [String, Number],
    },
  },
  setup() {
    const uuid: number = UniqueID();
    return { uuid };
  },
});
</script>
