<template>
  <div>
    <h1>Create an event</h1>
    <form @submit.prevent="sendForm">
      <BaseSelect
        label="Select a category"
        v-model="event.category"
        :options="categories"
      />

      <fieldset>
        <legend>Name & describe your event</legend>
        <BaseInput label="Title" type="text" v-model="event.title" />
        <BaseInput
          label="Description"
          type="text"
          v-model="event.description"
          error="this input has an error!!"
        />
      </fieldset>

      <fieldset>
        <legend>Where is your event?</legend>
        <BaseInput label="Location" type="text" v-model="event.location" />
      </fieldset>

      <fieldset>
        <legend>Pets</legend>
        <p>Are pets allowed?</p>

        <BaseRadioGroup
          name="pets"
          :options="petOptions"
          v-model="event.pets"
          vertical
        />
      </fieldset>

      <fieldset>
        <legend>Extras</legend>
        <div>
          <BaseCheckbox label="Catering" v-model="event.extras.catering" />
        </div>

        <div>
          <BaseCheckbox label="Live Music" v-model="event.extras.music" />
        </div>
      </fieldset>

      <button type="submit">Submit</button>
    </form>
  </div>
</template>

<script lang="ts">
import { defineComponent, reactive, ref, toRefs } from "vue";
import BaseInput from "@/components/BaseInput.vue";
import BaseSelect from "@/components/BaseSelect.vue";
import BaseCheckbox from "@/components/BaseCheckbox.vue";
import BaseRadioGroup from "@/components/BaseRadioGroup.vue";

export default defineComponent({
  name: "BasicForm",
  components: {
    BaseRadioGroup,
    BaseCheckbox,
    BaseSelect,
    BaseInput,
  },
  setup() {
    const data = reactive({
      categories: [
        "sustainability",
        "nature",
        "animal welfare",
        "housing",
        "education",
        "food",
        "community",
      ],
      petOptions: [
        { label: "Yes", value: 1 },
        { label: "No", value: 0 },
      ],
    });

    const event = ref({
      category: "",
      title: "",
      description: "",
      location: "",
      pets: 1,
      extras: {
        catering: false,
        music: false,
      },
    });

    const sendForm = () => {
      console.log(event);
    };

    return {
      ...toRefs(data),
      event,
      sendForm,
    };
  },
});
</script>

<style scoped>
fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

legend {
  font-size: 28px;
  font-weight: 700;
  margin-top: 20px;
}
</style>
