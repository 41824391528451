
import { defineComponent } from "vue";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const router = useRouter();
    const routes = router.options.routes;
    let baseRoutes: Array<string> = [];

    for (let i = 0; i < routes.length; i++) {
      if (!routes[i].path.includes(":")) {
        baseRoutes.push(routes[i].name as string);
      }
    }
    return { baseRoutes };
  },
});
